.react-calendar__month-view__weekdays__weekday {
  color: #0082ba;
}

.react-calendar__month-view__days__day {
  color: #ccc;
}

.react-calendar__month-view__days__day--neighboringMonth:hover {
  color: #0082ba;
}

.react-calendar__month-view__days__day--weekend {
  color: #ccc;
}

.react-calendar__month-view__days__day--weekend:hover {
  color: #0082ba;
}

.react-calendar__month-view__days__day--today {
  color: #0082ba;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: #1c3775;
  color: #fff;
}

.react-calendar__tile--active {
  background-color: #1c3775;
  border-radius: 200%;
}

.react-calendar__tile--now {
  background-color: #1c3775;
  border-radius: 200%;
  color: #fff;
}
.highlight {
  color: #2ad2c9;
  border-radius: 200%;
  width: 10px;
}
.disabled {
  pointer-events: none;
  color: #ccc !important;
  background-color: white !important;
}
