.crossmark {
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #ff0000;
  animation: error-fill 0.4s ease-in-out 0.4s forwards, error-scale 0.3s ease-in-out 0.9s both;
  margin: 0 auto;
}

.crossmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  fill: #fff;
  animation: error-stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.crossmark__cross {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: error-stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes error-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes error-scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes error-fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #ff0000;
  }
}
